<template>
  <a-table
    rowKey="id"
    :customRow="customRow"
    :data-source="list"
    :pagination="pagination"
    :loading="listLoading"
    @change="handleTableChange"
  >
    <a-table-column key="record" title="图片" align="center" :ellipsis="true">
      <template slot-scope="record">
        <a-row
          type="flex"
          align="middle"
          justify="space-between"
          :gutter="24"
          style="flex-wrap:nowrap;overflow:hidden;"
        >
          <a-col flex="0 0 56px">
            <a-radio :checked="selected.id === record.id"></a-radio>
          </a-col>

          <a-col flex="0 0 56px">
            <a-avatar :size="80" shape="square" :src="record.url"></a-avatar>
          </a-col>

          <a-col flex="0 0 56px"></a-col>
        </a-row>
      </template>
    </a-table-column>

    <a-table-column key="name" title="阶段名称" data-index="name" align="center" width="120px" />

    <a-table-column key="mainFieldName" title="阶段分类" data-index="mainFieldName" align="center" width="120px" />

    <a-table-column key="minimumRounds" title="回合数" data-index="minimumRounds" align="center" width="120px" />
  </a-table>
</template>

<script>
import * as phaseApi from '@/api/phase'

export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
      selected: {}
    }
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selected = record
            this.$emit('change', record)
          }
        }
      }
    },
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      this.listLoading = true

      return phaseApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        total: 0
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
