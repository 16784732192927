<template>
  <a-modal
    v-model="visible"
    title="关联阶段"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', disabled: !selected } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <a-row style="padding:24px">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="10" :md="12" :sm="24">
          <a-form-model-item label="搜索">
            <a-input-search
              v-model="name"
              allowClear
              placeholder="请输入阶段名称"
              style="width: 100%"
              @search="initData"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>

    <skill-phase-table ref="table" @change="e => (selected = e)"></skill-phase-table>
  </a-modal>
</template>
<script>
import * as phaseApi from '@/api/phase'
import SkillPhaseTable from './SkillPhaseTable'

export default {
  components: {
    SkillPhaseTable
  },
  props: {
    recordingMethod: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      name: '',
      selected: null
    }
  },
  methods: {
    showModal(query = {}) {
      this.visible = true

      this.query = query

      this.initData()
    },
    initData() {
      const {
        $nextTick,
        $refs,
        recordingMethod,
        name,
        query: { fieldId }
      } = this

      $nextTick(() => {
        $refs.table.initData({ fieldId, recordingMethod, name })
      })
    },
    handleOk() {
      const { selected } = this

      if (selected) {
        this.buttonLoading = true
        phaseApi
          .detail(selected.id)
          .then(res => {
            const { data } = res
            this.$emit('submit', data)
            this.selected = null
            this.visible = false
          })
          .finally(() => (this.buttonLoading = false))
      } else {
        this.$emit('submit', null)
        this.visible = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
