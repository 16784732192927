<!--建议训练辅助方式-->
<template>
  <div>
    <a-form-model :model="form" v-bind="$formItemLayout">
      <a-form-model-item label="是否需要记录辅助：" prop="isRecordAuxiliary">
        <a-radio-group v-model="form.isRecordAuxiliary">
          <a-radio :value="true"> 需要 </a-radio>
          <a-radio :value="false"> 不需要 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <auxiliary-mode-form
      :disabled="form.isRecordAuxiliary === true"
      v-for="(item, index) in list"
      :ref="`auxiliaryModeForm${index}`"
      :key="index"
      :my-key="index"
      :model="list[index]"
      :list="assistMethodOptions"
      @remove="handleRemove"
    ></auxiliary-mode-form>
    <a-form-model-item v-bind="$formItemLayoutWithoutLabel" v-if="list.length < maxAuxiliary">
      <a-button type="dashed" style="width: 100%" @click="add" :disabled="form.isRecordAuxiliary === false">
        <a-icon type="plus" />新增训练辅助方式</a-button
      >
    </a-form-model-item>
  </div>
</template>

<script>
import auxiliaryModeForm from './auxiliaryModeForm'
import * as commonApi from '@/api/common'

export default {
  components: {
    auxiliaryModeForm
  },
  data() {
    return {
      maxAuxiliary: 3, // PP最多3个
      form: {
        // 是否需要辅助
        isRecordAuxiliary: true
      },
      list: [],
      assistMethodOptions: [] // 选项
    }
  },
  mounted() {
    commonApi.dicts({ type: 'ASSIST_METHOD' }).then(res => {
      this.assistMethodOptions = res.data.map(v => v.value)
    })
  },
  methods: {
    initData(formList, isRecordAuxiliary) {
      this.list = formList
      this.form.isRecordAuxiliary = isRecordAuxiliary
    },
    add() {
      this.list.push({ assistMethod: '' })
    },
    handleRemove(index) {
      // 不要辅助-禁止删除
      if (this.form.isRecordAuxiliary === 'false') return
      this.list.splice(index, 1)
    },
    validate() {
      const { $refs, list } = this

      const valids = []
      for (let i = 0; i < list.length; i++) {
        const target = `auxiliaryModeForm${i}`

        valids.push(
          new Promise((resolve, reject) => {
            $refs[target][0].$refs.form.validate((valid, err) => {
              if (valid) {
                resolve(valid)
                return
              }
              reject(err)
            })
          })
        )
      }
      console.log(valids)
      return valids
    },
    formatForm() {
      const { $refs, list } = this
      const formList = []

      for (let i = 0; i < list.length; i++) {
        const target = `auxiliaryModeForm${i}`

        const item = $refs[target][0].formatForm()
        formList.push(item)
      }

      return formList
    }
  }
}
</script>

<style lang="less" scoped></style>
