<template>
  <div>
    <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
      <a-form-model-item :label="`训练辅助方式(PP${myKey + 1})`" prop="assistMethod">
        <a-row>
          <a-col>
            <h-select v-model="form.assistMethod" placeholder="请选择" :disabled="!disabled">
              <a-select-option v-for="assistMethodInfo in list" :key="assistMethodInfo" :value="assistMethodInfo">
                {{ assistMethodInfo }}
              </a-select-option>
            </h-select>
          </a-col>
          <a-icon
            v-if="myKey > 0"
            class="dynamic-delete-button"
            type="minus-circle-o"
            @click="$emit('remove', myKey)"
          />
        </a-row>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  name: 'AuxiliaryModeForm',
  props: {
    myKey: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      form: this.model
    }
  },
  watch: {
    model: {
      handler(newValue) {
        if (newValue) {
          newValue.assistMethod = newValue.assistMethod || ''
        }
        this.form = newValue
      },
      immediate: true
    }
  },
  methods: {
    formatForm() {
      const ret = cloneDeep(this.form)

      return { ...ret }
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic-delete-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
  &:hover {
    color: #f5222d;
  }
}
</style>
