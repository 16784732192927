<template>
  <a-form-model ref="form" :model="form" v-bind="!onlyMaterial ? $formItemLayout : { span: 24 }">
    <template v-if="!onlyMaterial">
      <skill-phase-modal
        ref="phaseModal"
        :recordingMethod="form.recordingMethod"
        @submit="handlePhaseChange"
      ></skill-phase-modal>
      <a-form-model-item
        label="阶段名称"
        prop="name"
        :rules="{
          required: true,
          message: '请填写阶段名称',
          trigger: 'blur',
        }"
      >
        <a-row v-if="type !== 'plan'" type="flex" align="middle">
          <a-col v-if="!form.phaseId" flex="1">
            <a-input v-model.trim="form.name" placeholder="请填写阶段名称" :maxLength="25" />
          </a-col>
          <a-col v-else>
            <span>{{ form.name }}</span>
          </a-col>

          <a-col style="margin-left: 16px">
            <a-button type="primary" shape="round" @click="handlePhaseSelect"> <a-icon type="link" />关联 </a-button>
          </a-col>
        </a-row>
        <span v-else>{{ form.name }}</span>
      </a-form-model-item>

      <a-form-model-item
        label="阶段目标"
        prop="goal"
        :rules="{
          required: true,
          message: '请填写阶段目标',
          trigger: ['change', 'blur'],
        }"
      >
        <div style="position: relative">
          <a-input
            v-model="form.goal"
            placeholder="请输入内容"
            :maxLength="300"
            :autoSize="{ minRows: 4, maxRows: 4 }"
            type="textarea"
          />
          <div style="position: absolute; bottom: -5px; right: 1px">
            <span style="background: #fff">{{ form.goal.length }}/300</span>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item
        label="最低回合数"
        prop="minimumRounds"
        :rules="{
          required: true,
          message: '请填写最低回合数',
          trigger: 'blur',
        }"
      >
        <a-row type="flex" align="middle">
          <a-col>
            <a-input-number
              v-model="form.minimumRounds"
              :min="1"
              :max="10"
              style="width: 80px; margin-right: 16px"
            ></a-input-number>
          </a-col>
          <a-col>回合<span class="disabled-color">（输入回合数不超过10）</span></a-col>
        </a-row>
      </a-form-model-item>

      <a-form-model-item
        label="记录方式"
        prop="recordingMethod"
        :rules="{
          required: true,
          message: '请选择记录方式',
          trigger: 'blur',
        }"
      >
        <h-select
          v-if="type !== 'plan' && !form.phaseId"
          v-model="form.recordingMethod"
          allowClear
          placeholder="请选择"
        >
          <a-select-option
            v-for="recordingMethodInfo in recordingMethodOptions"
            :key="recordingMethodInfo.value"
            :value="recordingMethodInfo.value"
          >
            {{ recordingMethodInfo.label }}
          </a-select-option>
        </h-select>
        <span v-else>{{ form.recordingMethod | RecordingMethodText() }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.recordingMethod"
        label="达标标准"
        prop="day"
        :rules="{
          validator: checkStandards,
          trigger: 'blur',
        }"
        :wrapperCol="{ span: 18 }"
      >
        <a-row type="flex" align="middle">
          <a-col>至少</a-col>
          <a-col>
            <a-input-number
              v-model="form.materialNumber"
              :min="0"
              :max="999"
              style="width: 70px; text-align: center; margin: 0 16px"
            ></a-input-number>
          </a-col>
          <a-col>个素材，每个正确率连续</a-col>
          <a-col>
            <a-input-number v-model="form.day" :min="0" :max="999" style="width: 70px; margin: 0 16px"></a-input-number>
          </a-col>
          <a-col>天达到</a-col>
          <template v-if="form.recordingMethod !== 'NUMBER_SUCCESS'">
            <a-col>
              <a-input-number
                v-model="form.successRate"
                :min="0"
                :max="100"
                style="width: 70px; margin: 0 16px"
              ></a-input-number>
            </a-col>
            <a-col>%</a-col>
          </template>
          <template v-else>
            <a-col>
              <a-input-number
                v-model="form.frequency"
                :min="0"
                :max="999"
                style="width: 70px; margin: 0 16px"
              ></a-input-number>
            </a-col>
            <a-col>次，每</a-col>
            <a-col>
              <a-input-number
                v-model="form.minutes"
                :min="0"
                :max="999"
                style="width: 70px; margin: 0 16px"
              ></a-input-number>
            </a-col>
            <a-col>分</a-col>
            <a-col>
              <a-input-number
                v-model="form.seconds"
                :min="0"
                :max="999"
                style="width: 70px; margin: 0 16px"
              ></a-input-number>
            </a-col>
            <a-col>秒</a-col>
          </template>
        </a-row>
      </a-form-model-item>

      <a-form-model-item
        v-if="form.recordingMethod === 'DURATION'"
        label="达标时长"
        prop="day"
        :rules="{
          validator: checkDuration,
          trigger: 'blur',
        }"
        :wrapperCol="{ span: 18 }"
      >
        <a-row type="flex" align="middle">
          <a-col>
            <a-input-number
              v-model="form.minutes"
              :min="0"
              :max="59"
              style="width: 80px; text-align: center; margin-right: 16px"
            ></a-input-number>
          </a-col>
          <a-col>分钟</a-col>
          <a-col>
            <a-input-number
              v-model="form.seconds"
              :min="0"
              :max="59"
              style="width: 80px; margin: 0 16px"
            ></a-input-number>
          </a-col>
          <a-col>秒</a-col>
        </a-row>
      </a-form-model-item>

      <template v-if="form.recordingMethod === 'STEP_SUCCESS_RATE'">
        <a-form-model-item
          v-for="(stepsDescInfo, index) in form.stepsDescriptionList"
          :key="index"
          v-bind="index !== 0 ? $formItemLayoutWithoutLabel : {}"
          :label="index === 0 ? '步骤描述' : ''"
          :prop="'stepsDescriptionList.' + index + '.value'"
          :rules="{
            required: true,
            message: '请填写步骤描述',
            trigger: 'blur',
          }"
        >
          <a-input v-model="stepsDescInfo.value" :placeholder="`请输入第${index + 1}步`" :maxLength="500" />
          <a-icon
            v-if="form.stepsDescriptionList.length > 1"
            class="dynamic-delete-button"
            type="minus-circle-o"
            :disabled="form.stepsDescriptionList.length === 1"
            @click="removeStepDesc(stepsDescInfo)"
          />
        </a-form-model-item>

        <a-form-model-item v-bind="$formItemLayoutWithoutLabel">
          <a-button type="dashed" style="width: 100%" @click="addStepDesc"><a-icon type="plus" />新增步骤</a-button>
        </a-form-model-item>
      </template>

      <a-form-model-item
        label="素材说明"
        prop="materialDescription"
        :rules="{
          required: true,
          message: '请填写素材说明',
          trigger: ['change', 'blur'],
        }"
      >
        <div v-if="type !== 'plan'" style="position: relative">
          <a-input
            v-model="form.materialDescription"
            placeholder="请输入内容"
            :maxLength="300"
            :autoSize="{ minRows: 4, maxRows: 4 }"
            type="textarea"
          />
          <div style="position: absolute; bottom: -5px; right: 1px">
            <span style="background: #fff">{{ form.materialDescription.length }}/300</span>
          </div>
        </div>
        <p v-else class="pre">{{ form.materialDescription }}</p>
      </a-form-model-item>
    </template>
    <!-- {{ type }} {{ form.material }} -->
    <a-form-model-item
      :label="!onlyMaterial ? '训练素材' : ''"
      prop="material"
      :rules="{
        required: true,
        message: '请添加训练素材',
        trigger: 'blur',
      }"
    >
      <!-- 技能包管理-阶段-编辑、新增 -->
      <!-- {{ materialOptions }} -->
      <a-card v-if="type !== 'plan'" :bodyStyle="{ padding: '16px' }">
        <a-checkbox-group
          v-show="materialOptions.length > 0"
          v-model="form.material"
          style="width: 100%; margin-bottom: 16px"
        >
          <a-row type="flex" :gutter="[16, 16]">
            <a-col v-for="item in materialOptions" :key="item.name" :span="6">
              <a-popover>
                <template slot="content">
                  <span @click="changeMaterial(item)">编辑</span>
                </template>
                <a-checkbox :value="item.name">
                  {{ item.name }}
                </a-checkbox>
              </a-popover>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <a-space>
          <a-input placeholder="素材最多为9个字" :max-lehght="9" v-model="newMaterial"></a-input>
          <a-button type="link" @click="addMaterial">添加</a-button>
        </a-space>
      </a-card>
      <!-- 家校训练服务-训练计划-阶段-编辑信息 -->
      <a-card v-else :bodyStyle="{ padding: '16px' }">
        <div class="card-top">
          <p class="top-title title">当前训练素材</p>
          <p class="top-sub">请从下方"训练素材库"中点击加入</p>
          <a-checkbox-group v-model="form.material" style="width: 100%; margin-bottom: 16px">
            <a-row :gutter="[16, 16]" type="flex" justify="start" style="margin: 0">
              <div
                v-for="(item, index) in form.material"
                :key="item"
                style="display: flex; margin-bottom: 5px; margin-right: 10px"
              >
                <a-checkbox :value="item">
                  {{ item }}
                </a-checkbox>
                <div v-if="materialsAdded && materialsAdded.length">
                  <template v-if="materialsAdded[index] && materialsAdded[index].round">
                    <span class="tag">{{
                      materialsAdded[index].isQualified
                        ? `已达标训练${materialsAdded[index].round}次`
                        : `已训练${materialsAdded[index].round}次`
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="tag">未训练</span>
                  </template>
                </div>
              </div>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="card-bottom">
          <p class="bottom-title title">训练素材库</p>
          <a-checkbox-group
            v-show="materialOptions.length > 0"
            v-model="form.material"
            style="width: 100%; margin-bottom: 16px"
          >
            <a-row :gutter="[16, 16]" type="flex" justify="start" style="margin: 0">
              <div
                v-for="item in materialOptions"
                :key="item.material"
                style="display: flex; margin-bottom: 5px; margin-right: 10px"
              >
                <a-checkbox v-if="!isSelfmaterial(item)" :value="item.material">
                  {{ item.material }}
                </a-checkbox>
                <template v-else>
                  <a-popover placement="topLeft" v-if="item.type === 'SELF'">
                    <template slot="content">
                      <p @click="planchangeMaterial(item)">编辑</p>
                      <a-popconfirm
                        v-if="!item.round"
                        title="你确定要删除吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="plandeleteMaterial(item)"
                      >
                        删除
                      </a-popconfirm>
                    </template>
                    <a-checkbox :value="item.material">
                      {{ item.material }}
                    </a-checkbox>
                  </a-popover>
                  <a-checkbox :value="item.material" v-else>
                    {{ item.material }}
                  </a-checkbox>
                </template>

                <template v-if="item.round">
                  <span class="tag">{{
                    item.isQualified ? `已达标训练${item.round}次` : `已训练${item.round}次`
                  }}</span>
                </template>
                <template v-else>
                  <span class="tag">未训练</span>
                </template>
              </div>
            </a-row>
          </a-checkbox-group>
          <a-space>
            <a-input placeholder="素材最多为9个字" v-model="newMaterial" :max-length="9"></a-input>
            <a-button type="link" @click="handlePlanaddMaterial">添加</a-button>
          </a-space>
        </div>
      </a-card>
      <!-- 修改素材 -->
      <a-modal
        v-model="Materialvisible"
        title="修改素材"
        width="716px"
        :destroyOnClose="true"
        :ok-button-props="{ props: { shape: 'round' } }"
        :cancel-button-props="{ props: { shape: 'round' } }"
        @ok="handleModal"
      >
        <a-form-model-item label="原素材">
          <div>{{ type === 'default' ? beforeMaterial.name : beforeMaterial.material }}</div>
        </a-form-model-item>
        <a-form-model-item label="新素材" style="display: flex; justify-content: flex-start; align-items: center">
          <a-input
            placeholder="素材最多为9个字"
            v-model="afterMaterial"
            :max-length="9"
            :style="{ width: 100, marginLeft: 10 }"
          />
        </a-form-model-item>
      </a-modal>
    </a-form-model-item>
    <template v-if="!onlyMaterial">
      <a-form-model-item
        label="训练方式"
        prop="trainingMethod"
        :rules="{
          required: true,
          message: '请选择训练方式',
          trigger: 'blur',
        }"
      >
        <h-select v-model="form.trainingMethod" placeholder="请选择">
          <a-select-option
            v-for="trainingMethodInfo in trainingMethodOptions"
            :key="trainingMethodInfo.value"
            :value="trainingMethodInfo.value"
          >
            {{ trainingMethodInfo.label }}
          </a-select-option>
        </h-select>
      </a-form-model-item>
      <a-form-model-item
        label="建议辅助方式"
        prop="assistMethod"
        :rules="{
          required: true,
          message: '请选择建议辅助方式',
          trigger: 'change',
        }"
      >
        <h-select v-model="form.assistMethod" mode="multiple" placeholder="请选择">
          <a-select-option
            v-for="assistMethodInfo in assistMethodOptions"
            :key="assistMethodInfo"
            :value="assistMethodInfo"
          >
            {{ assistMethodInfo }}
          </a-select-option>
        </h-select>
      </a-form-model-item>
      <!--3个PP123-->
      <auxiliary-mode-list ref="auxiliary"></auxiliary-mode-list>

      <a-form-model-item
        label="注意事项"
        prop="precautions"
        :rules="{
          required: true,
          message: '请填写注意事项',
          trigger: ['change', 'blur'],
        }"
      >
        <div style="position: relative">
          <a-input
            v-model="form.precautions"
            placeholder="请输入内容"
            :maxLength="500"
            :autoSize="{ minRows: 3, maxRows: 3 }"
            type="textarea"
          />
          <div style="position: absolute; bottom: -5px; right: 1px">
            <span style="background: #fff">{{ form.precautions.length }}/500</span>
          </div>
        </div>
      </a-form-model-item>

      <preparation-form-list ref="preparation" title="学前准备"></preparation-form-list>

      <a-form-model-item v-bind="$formItemLayoutWithoutLabel">
        <a-button type="dashed" style="width: 100%" @click="addPreparation">
          <a-icon type="plus" />新增学前准备</a-button
        >
      </a-form-model-item>
    </template>
  </a-form-model>
</template>
<script>
import { cloneDeep, debounce } from 'lodash'
import * as commonApi from '@/api/common'
import * as planApi from '@/api/plan'
import { TRAINING_METHOD, RECORDING_METHOD } from '@/store/dictionary'
import PreparationFormList from '../../components/PreparationFormList'
import SkillPhaseModal from './SkillPhaseModal'
import AuxiliaryModeList from '@/views/home-school-service/edit/plan/AuxiliaryModeList.vue'

import { mapActions } from 'vuex'

export default {
  components: {
    SkillPhaseModal,
    PreparationFormList,
    AuxiliaryModeList
  },
  props: {
    // 页面来源（1、为家校训练服务-详情-训练计划-编辑 2、家校服务-上课-结束课程后的页面）
    origin: {
      type: String,
      default: ''
    },
    // 家校服务-上课-结束课程后的页面,只操作素材
    onlyMaterial: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    mainFieldId: {
      type: Number,
      default: undefined
    },
    fieldId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: {
        id: undefined,
        phaseId: undefined,
        name: '',
        goal: '',
        minimumRounds: 1,
        recordingMethod: undefined,
        materialNumber: 0,
        day: 0,
        successRate: 0,
        frequency: 0,
        minutes: 0,
        seconds: 0,
        stepsDescriptionList: [{ value: '' }],
        materialDescription: '',
        material: [],
        trainingMethod: undefined,
        assistMethod: [],
        precautions: '',
        trainingPreparationList: []
      },
      recordingMethodOptions: RECORDING_METHOD,
      trainingMethodOptions: TRAINING_METHOD,
      assistMethodOptions: [],
      newMaterial: undefined,
      materialDicts: [],
      Materialvisible: false, // 编辑素材弹窗
      beforeMaterial: '', // 旧素材
      afterMaterial: '', // 新素材
      materialsAdded: []
    }
  },
  watch: {
    fieldId() {
      console.log('change field')
      if (this.form.phaseId) {
        // this.handlePhaseChange({})
      }
    },
    'form.material'(newVal, oldVal) {
      if (!newVal || !oldVal) return
      // 减少
      if (newVal.length < oldVal.length && oldVal) {
        const subItem = [...oldVal].filter(item => [...newVal].every(i => i !== item))
        let key = -1
        this.materialsAdded.map((item, index) => {
          if (item.material === subItem[0]) {
            key = index
          }
        })
        if (key > -1) {
          this.materialsAdded.splice(key, 1)
        }
      }
      // 增加
      if (newVal.length > oldVal.length && oldVal) {
        const item = this.materialOptions.filter(item => {
          return item.material === newVal[newVal.length - 1]
        })[0]
        this.materialsAdded.push(item)
      }
      this.$forceUpdate()
    }
  },
  computed: {
    materialOptions() {
      return Array.from(new Set(this.materialDicts))
    }
  },
  mounted() {
    commonApi.dicts({ type: 'ASSIST_METHOD' }).then(res => {
      this.assistMethodOptions = res.data.map(v => v.value)
    })
  },
  methods: {
    ...mapActions(['changeSpinning']),
    checkStandards(rule, value, callback) {
      const { recordingMethod, materialNumber, day, successRate, frequency, seconds, minutes } = this.form

      if (['SUCCESS_RATE', 'DURATION', 'STEP_SUCCESS_RATE'].includes(recordingMethod)) {
        if (materialNumber < 1) {
          callback(new Error('素材数范围1~999'))
        } else if (day < 1) {
          callback(new Error('天数范围1~999'))
        } else if (successRate < 1) {
          callback(new Error('正确率范围1~100'))
        } else {
          callback()
        }
      } else if (recordingMethod === 'NUMBER_SUCCESS') {
        if (materialNumber < 1) {
          callback(new Error('素材数范围1~999'))
        } else if (day < 1) {
          callback(new Error('天数范围1~999'))
        } else if (frequency < 1) {
          callback(new Error('次数数范围1~999'))
        } else if (minutes < 0) {
          callback(new Error('分数范围0~999'))
        } else if (seconds < 0) {
          callback(new Error('秒数范围0~999'))
        } else {
          callback()
        }
      }
    },
    checkDuration(rule, value, callback) {
      const { minutes, seconds } = this.form

      if (minutes > 0 || seconds > 0) {
        callback()
      } else {
        callback(new Error('时长不可为0'))
      }
    },
    // 技能包管理阶段初始信息
    initData(form) {
      const {
        id,
        phaseId,
        name,
        goal,
        minimumRounds,
        recordingMethod,
        materialNumber,
        day,
        successRate,
        frequency,
        minutes,
        seconds,
        materialDescription,
        trainingMethod,
        assistMethod,
        precautions,
        trainingPreparationList,
        skillPhaseMaterialList
      } = form
      let { stepsDescriptionList } = form
      const material = []
      this.materialDicts = skillPhaseMaterialList
      form.skillPhaseMaterialList.forEach(el => {
        if (el.selected) {
          material.push(el.name)
        }
      })
      if (trainingPreparationList.length > 0) {
        this.$refs.preparation.initData(trainingPreparationList)
      }

      if (stepsDescriptionList && stepsDescriptionList.length > 0) {
        stepsDescriptionList = stepsDescriptionList.map(v => ({ value: v }))
      } else {
        stepsDescriptionList = [{ value: '' }]
      }
      this.form = {
        id,
        phaseId,
        name,
        goal,
        minimumRounds: minimumRounds > 0 ? minimumRounds : 1,
        recordingMethod,
        materialNumber,
        day,
        successRate,
        frequency,
        minutes,
        seconds,
        stepsDescriptionList,
        materialDescription,
        material,
        trainingMethod,
        assistMethod,
        precautions,
        trainingPreparationList
      }
    },
    // 训练计划阶段信息
    planinitData(form, studentId) {
      const {
        id,
        phaseId,
        name,
        goal,
        minimumRounds,
        recordingMethod,
        materialNumber,
        day,
        successRate,
        frequency,
        minutes,
        seconds,
        materialDescription,
        // material,
        // materials,
        trainingMethod,
        assistMethod,
        precautions,
        trainingPreparationList,
        trainingAssistanceMode1,
        trainingAssistanceMode2,
        trainingAssistanceMode3,
        isRecordAuxiliary
      } = form
      let { stepsDescriptionList } = form
      // this.materialDicts = materials
      if (trainingPreparationList.length > 0 && !this.onlyMaterial) {
        this.$refs.preparation.initData(trainingPreparationList)
      }

      if (stepsDescriptionList && stepsDescriptionList.length > 0) {
        stepsDescriptionList = stepsDescriptionList.map(v => ({ value: v }))
      } else {
        stepsDescriptionList = [{ value: '' }]
      }

      this.form = {
        id,
        phaseId,
        name,
        goal,
        minimumRounds: minimumRounds > 0 ? minimumRounds : 1,
        recordingMethod,
        materialNumber,
        day,
        successRate,
        frequency,
        minutes,
        seconds,
        stepsDescriptionList,
        materialDescription,
        // material: [],
        trainingMethod,
        assistMethod,
        precautions,
        trainingPreparationList,
        isRecordAuxiliary
        // materials: []
      }
      // PP123
      const trainingAssistanceModeList = cloneDeep(
        [trainingAssistanceMode1, trainingAssistanceMode2, trainingAssistanceMode3].filter(Boolean)
      )
      // 添加过PP123
      if (!this.onlyMaterial) {
        if (trainingAssistanceModeList.length > 0) {
          const temp = trainingAssistanceModeList.map(item => ({
            assistMethod: item
          }))
          this.$refs.auxiliary.initData(temp, isRecordAuxiliary)
        } else {
          // 未添加过,并且有辅助方式，取辅助方式第一个给pp1
          if (assistMethod) {
            const first = cloneDeep(assistMethod).shift()
            const temp = [{ assistMethod: first }]
            this.$refs.auxiliary.initData(temp, isRecordAuxiliary)
          }
        }
      }

      planApi
        .getStudentPhaseDetail({ phaseId: id, studentId })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            const { materials, material, materialsAdded } = cloneDeep(data)
            const tempMaterial = material.map(item => item.materialName)
            this.materialDicts = materials
            const tempMaterialsAdded = []
            material &&
              material.map((item, index) => {
                const temp = materialsAdded.filter((item1, index1) => {
                  return item1.material === item.materialName
                })[0]
                tempMaterialsAdded.push(temp)
              })
            this.materialsAdded = cloneDeep(tempMaterialsAdded)
            this.form = cloneDeep({ ...this.form, ...{ materials, material: tempMaterial } })
            this.$forceUpdate()
          }
          this.changeSpinning(false)
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },
    validate() {
      const {
        $refs: { form, preparation }
      } = this

      const basisValid = new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
      const valids = [basisValid]

      // 只操作素材，跳过验证
      if (!this.onlyMaterial) {
        const preprationValid = preparation.validate()
        if (preprationValid.length > 0) {
          valids.push(...preprationValid)
        }
      }

      return Promise.all(valids)
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        const { materialOptions } = this
        const { recordingMethod, id } = ret
        let {
          // eslint-disable-next-line prefer-const
          material,
          day,
          successRate,
          frequency,
          minutes,
          seconds,
          stepsDescriptionList,
          materialNumber
        } = ret
        // 计算素材个数
        materialNumber = id ? materialNumber : material.length || 0
        // 向后端传递的素材数组
        materialOptions.forEach(el => {
          if (material.includes(el.name)) {
            el.selected = true
          } else {
            el.selected = false
          }
        })
        day = day || 0
        successRate = successRate || 0
        frequency = frequency || 0
        minutes = minutes || 0
        seconds = seconds || 0

        if (recordingMethod === 'STEP_SUCCESS_RATE') {
          stepsDescriptionList = stepsDescriptionList ? stepsDescriptionList.map(v => v.value) : []
        } else {
          stepsDescriptionList = []
        }

        const trainingPreparationList = this.$refs.preparation.formatForm()
        console.log(materialNumber)

        return {
          ...ret,
          materialNumber,
          day,
          successRate,
          frequency,
          minutes,
          seconds,
          stepsDescriptionList,
          trainingPreparationList,
          skillPhaseMaterialList: materialOptions
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 家校-训练计划添加素材
    planformatForm() {
      console.log('家校-训练计划添加素材')
      try {
        const ret = cloneDeep(this.form)

        const { recordingMethod } = ret

        let {
          // eslint-disable-next-line prefer-const
          material,
          day,
          successRate,
          frequency,
          minutes,
          seconds,
          materialNumber,
          stepsDescriptionList
        } = ret
        // 计算素材个数(编辑时以用户输入为准)
        materialNumber =
          ['homeSchoolDetail', 'trainingCompleted'].indexOf(this.origin) > -1 ? materialNumber : material.length || 0
        // 向后端传递的素材数组
        const materialDTOList = []
        this.materialOptions.forEach(el => {
          if (material.includes(el.material)) {
            materialDTOList.push({
              id: null,
              materialType: el.type,
              name: el.material,
              selected: true
            })
          } else {
            materialDTOList.push({
              id: null,
              materialType: el.type,
              name: el.material,
              selected: false
            })
          }
        })
        day = day || 0
        successRate = successRate || 0
        frequency = frequency || 0
        minutes = minutes || 0
        seconds = seconds || 0

        if (recordingMethod === 'STEP_SUCCESS_RATE') {
          stepsDescriptionList = stepsDescriptionList ? stepsDescriptionList.map(v => v.value) : []
        } else {
          stepsDescriptionList = []
        }
        const trainingPreparationList = !this.onlyMaterial ? this.$refs.preparation.formatForm() : []

        // PP123
        const trainingAssistanceMode = {}
        if (!this.onlyMaterial && this.$refs.auxiliary.form.isRecordAuxiliary === true) {
          const trainingAssistanceModeList = this.$refs.auxiliary.formatForm()
          trainingAssistanceModeList &&
            trainingAssistanceModeList.map((item, index) => {
              const { assistMethod } = item
              const key = `trainingAssistanceMode${index + 1}`
              trainingAssistanceMode[key] = assistMethod
            })
        }

        return {
          ...trainingAssistanceMode,
          ...ret,
          materialNumber,
          day,
          successRate,
          frequency,
          minutes,
          seconds,
          stepsDescriptionList,
          trainingPreparationList,
          materialDTOList,
          isRecordAuxiliary: !this.onlyMaterial
            ? this.$refs.auxiliary.form.isRecordAuxiliary
            : this.form.isRecordAuxiliary
        }
      } catch (error) {
        console.log(error)
      }
    },
    handlePhaseSelect() {
      const { fieldId, $refs } = this
      $refs.phaseModal.showModal({ fieldId })
    },
    handlePhaseChange({ id, name = '', minimumRounds = 1, recordingMethod = '', introduction = '', precautions = '' }) {
      this.form.phaseId = id
      this.form.name = name
      this.form.goal = introduction
      this.form.precautions = precautions
      this.form.minimumRounds = minimumRounds
      this.form.recordingMethod = recordingMethod
    },
    // 新增学前准备
    addPreparation() {
      this.$refs.preparation.add()
    },
    checkMaterialNameReg(mat) {
      let regResult = false
      if (/^[a-zA-Z]+$/.test(mat)) {
        regResult = true
      }
      if (/^[0-9]*$/.test(mat)) {
        regResult = true
      }
      if (/^[\u4E00-\u9FA5]+$/.test(mat)) {
        regResult = true
      }
      return regResult
    },
    checkMaterial(mat) {
      const { materialOptions } = this
      const matopt = materialOptions.map(el => el.material)
      console.log(matopt)
      let tempBoolean = true
      if (!mat) {
        this.$notification.error({
          message: '错误',
          description: '不能添加空素材'
        })
        tempBoolean = false
      } else if (/[\s*]/.test(mat)) {
        this.$notification.error({
          message: '错误',
          description: '素材名不能包含空格'
        })
        tempBoolean = false
      } else if (mat.length > 9) {
        this.$notification.error({
          message: '错误',
          description: '素材最多为9个字'
        })
        tempBoolean = false
      } else if (matopt.includes(mat)) {
        this.$notification.error({
          message: '错误',
          description: '不能添加重复素材'
        })
        tempBoolean = false
      }
      // else if (!this.checkMaterialNameReg(mat)) {
      //   this.$notification.error({
      //     message: '错误',
      //     description: '请用纯文字、纯数字或者纯字母创建素材'
      //   })
      //   tempBoolean = false
      // }
      return tempBoolean
    },
    checkskillMaterial(mat) {
      const { materialOptions } = this
      const matopt = materialOptions.map(el => el.name)
      let tempBoolean = true
      if (!mat) {
        this.$notification.error({
          message: '错误',
          description: '不能添加空素材'
        })
        tempBoolean = false
      } else if (/[\s*]/.test(mat)) {
        this.$notification.error({
          message: '错误',
          description: '素材名不能包含空格'
        })
        tempBoolean = false
      } else if (mat.length > 9) {
        this.$notification.error({
          message: '错误',
          description: '素材最多为9个字'
        })
        tempBoolean = false
      } else if (matopt.includes(mat)) {
        this.$notification.error({
          message: '错误',
          description: '不能添加重复素材'
        })
        tempBoolean = false
      }
      // else if (!this.checkMaterialNameReg(mat)) {
      //   this.$notification.error({
      //     message: '错误',
      //     description: '请用纯文字、纯数字或者纯字母创建素材'
      //   })
      //   tempBoolean = false
      // }
      return tempBoolean
    },
    handleModal() {
      if (this.type === 'plan') {
        this.planhandlechangeMaterial()
      } else {
        this.handlechangeMaterial()
      }
    },
    // 添加素材
    addMaterial() {
      const { newMaterial, materialOptions } = this
      if (this.checkskillMaterial(newMaterial)) {
        newMaterial && materialOptions.push({ id: null, name: newMaterial, selected: true })
        this.form.material.push(newMaterial)
      }
      this.newMaterial = ''
    },
    // 编辑素材
    changeMaterial(item) {
      this.beforeMaterial = item
      this.Materialvisible = true
    },
    handlechangeMaterial() {
      const { beforeMaterial, afterMaterial, materialOptions } = this
      if (this.checkskillMaterial(afterMaterial)) {
        const formIndex = this.form.material.findIndex(el => el === beforeMaterial.name)
        this.form.material.splice(formIndex, 1, afterMaterial)
        const optIndex = materialOptions.findIndex(el => el.name === beforeMaterial.name)
        this.materialOptions[optIndex].name = afterMaterial
      }
      this.beforeMaterial = ''
      this.afterMaterial = ''
      this.Materialvisible = false
    },
    // 验证是否是自定义素材
    isSelfmaterial(item) {
      if (!item?.type) return
      if (item.type === 'SELF') {
        if (this.form.material.includes(item.material)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    // 家校-训练计划添加素材-按钮
    handlePlanaddMaterial() {
      this.planaddMaterial()
    },
    // 家校-训练计划添加素材( 注意，这里debounce中的第一个参数，不能写成箭头函数，否则，取不到this)
    planaddMaterial: debounce(function() {
      const { newMaterial, materialOptions } = this
      if (this.checkMaterial(newMaterial)) {
        newMaterial && materialOptions.push({ material: newMaterial, type: 'SELF' })
      }
      this.newMaterial = ''
    }, 300),
    // 家校-训练计划编辑素材
    planchangeMaterial(item) {
      this.beforeMaterial = item
      this.Materialvisible = true
    },
    planhandlechangeMaterial() {
      const { beforeMaterial, afterMaterial, materialOptions } = this
      if (this.checkMaterial(afterMaterial)) {
        if(beforeMaterial?.materialId){
          const { materialId } = beforeMaterial
          const params = { planPhaseMaterialId: materialId, materialType: 'SELF', name: afterMaterial }
          planApi.editPlanPhaseMaterial(params).then(res => {
            const { code } = res
            if (code === 0) {
              const optIndex = materialOptions.findIndex(el => el.material === beforeMaterial.material)
              afterMaterial &&
                this.materialOptions.splice(optIndex, 1, {
                  ...beforeMaterial,
                  ...{ material: afterMaterial, type: 'SELF' }
                })

              this.$emit('initData')
            }
          })
        }else{
          const optIndex = materialOptions.findIndex(el => el.material === beforeMaterial.material)
          afterMaterial &&
            this.materialOptions.splice(optIndex, 1, {
              ...beforeMaterial,
              ...{ material: afterMaterial, type: 'SELF' }
            })
        }
      }

      this.beforeMaterial = ''
      this.afterMaterial = ''
      this.Materialvisible = false
    },
    // 家校-训练计划删除自定义素材
    plandeleteMaterial(item) {
      const { materialId, material } = item
      if (materialId) {
        planApi.deletePlanPhaseMaterial(materialId).then(res => {
          const { code } = res
          if (code === 0) {
            const optIndex = this.materialOptions.findIndex(el => el.materialId === materialId)
            this.materialOptions.splice(optIndex, 1)
            this.$forceUpdate()
          }
        })
      } else {
        const optIndex = this.materialOptions.findIndex(el => el.material === material)
        this.materialOptions.splice(optIndex, 1)
        this.$forceUpdate()
      }
    },
    addStepDesc() {
      this.form.stepsDescriptionList.push({ value: '' })
    },
    removeStepDesc(item) {
      const index = this.form.stepsDescriptionList.indexOf(item)
      if (index !== -1) {
        this.form.stepsDescriptionList.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-children {
  position: relative;
}
/deep/ .ant-input-number-input {
  text-align: center;
}
/deep/ .ant-checkbox-wrapper {
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  margin-left: 0;
}
/deep/ .ant-checkbox {
  margin-top: 4px;
}
.dynamic-delete-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
  &:hover {
    color: #f5222d;
  }
}
p.pre {
  line-height: 2;
  margin-top: 6px;
}
.card-top {
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  .top-sub {
    margin-top: 0;
    font-size: 12px;
    color: #666666;
  }
}
.title {
  font-size: 16px;
  color: #000000;
}
.tag {
  background: rgba(58, 95, 248, 0.1);
  border-radius: 1px;
  font-size: 12px;
  color: #3a5ff8;
  padding: 3px 8px;
}
</style>
